
































import { bookMarkSearvice } from '@/services/bookMark';
import { CONTENT_TYPE } from '@/utils';
import { defineComponent, onMounted, ref } from '@vue/composition-api';

import { IBookMark } from '@/models';

import { Observer, LearnPackCard, LearnPackCardSkeleton } from '@/components';
import { RountName } from '@/router';
import { learnPackSearvice } from '@/services';

export default defineComponent({
  name: 'MyLearnPackList',
  components: {
    Observer,
    LearnPackCard,
    LearnPackCardSkeleton,
  },
  setup(_, { root }) {
    const isLoading = ref(false);

    const page = ref(1);
    const lastPage = ref(false);

    const list = ref<IBookMark[]>([]);

    async function fetchData() {
      if (isLoading.value) return;

      isLoading.value = true;

      try {
        const response = await learnPackSearvice.getMyLearnPackList({
          page: page.value,
          size: 20,
        });

        list.value = [...list.value, ...response.content];
        lastPage.value = response.last;

        if (!response.last) {
          page.value += 1;
        }
      } catch {
        // pass
      } finally {
        isLoading.value = false;
      }
    }

    function handleIntersect() {
      fetchData();
    }

    async function handleToggleBookMark({
      marked,
      learnPackId,
      listIndex,
      bookMarkId,
    }: {
      marked: boolean;
      learnPackId: number;
      listIndex: number;
      bookMarkId: number | null;
    }) {
      try {
        if (marked) {
          const newBookMarkId = await bookMarkSearvice.createBookMark({
            contentId: learnPackId,
            contentType: CONTENT_TYPE.LearnPack,
          });

          list.value[listIndex].bookMarkId = newBookMarkId;
        } else {
          if (!bookMarkId) return;
          await bookMarkSearvice.deleteBookMark(bookMarkId);
        }

        list.value[listIndex].bookMarked = marked;
      } catch {
        // pass
      }
    }

    function handleClickLearnPack(id: number) {
      root.$router.push({
        name: RountName.LearnPackEdit,
        params: {
          learnPackId: String(id),
        },
      });
    }

    onMounted(() => {
      fetchData();
    });

    return {
      isLoading,
      list,

      lastPage,

      handleIntersect,

      handleToggleBookMark,
      handleClickLearnPack,
    };
  },
});
