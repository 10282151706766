




























































































import { IUserDetail } from '@/models';
import { RountName } from '@/router';
import { authSearvice } from '@/services';
import { CONTENT_TYPE, MYPAGE_MENU_TYPE, useDevice } from '@/utils';
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';

import { LectureList, BookList, LearnPackList, MyLearnPackList } from './components';

export default defineComponent({
  name: 'MypageIndex',
  components: {
    LectureList,
    BookList,
    LearnPackList,
    MyLearnPackList,
  },
  setup(_, { root }) {
    const { isMobile } = useDevice();

    const selectedContentType = ref<CONTENT_TYPE>(CONTENT_TYPE.Lecture);

    const isProfileImageVisible = ref(true);

    const selectedMenu = ref<MYPAGE_MENU_TYPE>(MYPAGE_MENU_TYPE.Bookmark);

    const userData = reactive<IUserDetail>({
      email: '',
      nickName: '',
      introduction: '',
      position: '',
      profileUrl: '',
      referenceUrls: [],
      userId: 0,
    });

    const component = computed(() => {
      if (selectedContentType.value === CONTENT_TYPE.Lecture) {
        return LectureList;
      }

      if (selectedContentType.value === CONTENT_TYPE.Book) {
        return BookList;
      }

      if (selectedContentType.value === CONTENT_TYPE.LearnPack) {
        return LearnPackList;
      }

      return null;
    });

    function handleClickTab(type: CONTENT_TYPE) {
      selectedContentType.value = type;

      window.scrollTo({ top: 0 });
    }

    async function fetchUserDetail() {
      try {
        const response = await authSearvice.getDetail();

        userData.email = response.email;
        userData.nickName = response.nickName;
        userData.introduction = response.introduction;
        userData.position = response.position;
        userData.profileUrl = response.profileUrl;
        userData.referenceUrls = response.referenceUrls;
        userData.userId = response.userId;
      } catch (error) {
        //
      }
    }

    function handleErrorImage() {
      isProfileImageVisible.value = false;
    }

    function handleClickSetting() {
      root.$router.push({ name: RountName.MypageSetting });
    }

    function handleClickMenu(type: MYPAGE_MENU_TYPE) {
      selectedMenu.value = type;
    }

    watch(selectedMenu, () => {
      root.$router.replace({
        query: {
          menu: selectedMenu.value,
        },
      });
    });

    onMounted(() => {
      const query = root.$route.query?.menu;
      if (query && typeof query === 'string') {
        selectedMenu.value = query;
      }
      fetchUserDetail();
    });

    return {
      CONTENT_TYPE,
      MYPAGE_MENU_TYPE,

      isMobile,

      selectedContentType,
      selectedMenu,

      userData,
      isProfileImageVisible,

      component,

      handleErrorImage,

      handleClickTab,
      handleClickSetting,
      handleClickMenu,
    };
  },
});
