



























import { bookMarkSearvice } from '@/services/bookMark';
import { CONTENT_TYPE } from '@/utils';
import { defineComponent, onMounted, ref } from '@vue/composition-api';

import { IBookMark } from '@/models';

import { Observer, BookCard, BookCardSkeleton } from '@/components';

export default defineComponent({
  name: 'LectureList',
  components: {
    BookCard,
    BookCardSkeleton,
    Observer,
  },
  setup() {
    const isLoading = ref(false);

    const page = ref(1);
    const lastPage = ref(false);

    const list = ref<IBookMark[]>([]);

    async function fetchData() {
      if (isLoading.value) return;

      isLoading.value = true;

      try {
        const response = await bookMarkSearvice.getBookMarkList({
          contentType: CONTENT_TYPE.Book,
          page: page.value,
        });

        list.value = [...list.value, ...response.content];
        lastPage.value = response.last;

        if (!response.last) {
          page.value += 1;
        }
      } catch {
        // pass
      } finally {
        isLoading.value = false;
      }
    }

    function handleIntersect() {
      fetchData();
    }

    function handleClickOpenLink(link: string) {
      window.open(link, '_blank');
    }

    async function handleToggleBookMark({
      marked,
      bookId,
      listIndex,
      bookMarkId,
    }: {
      marked: boolean;
      bookId: number;
      listIndex: number;
      bookMarkId: number | null;
    }) {
      try {
        if (marked) {
          const newBookMarkId = await bookMarkSearvice.createBookMark({
            contentId: bookId,
            contentType: CONTENT_TYPE.Book,
          });

          list.value[listIndex].bookMarkId = newBookMarkId;
        } else {
          if (!bookMarkId) return;
          await bookMarkSearvice.deleteBookMark(bookMarkId);
        }

        list.value[listIndex].bookMarked = marked;
      } catch {
        // pass
      }
    }

    onMounted(() => {
      fetchData();
    });

    return {
      isLoading,
      list,

      lastPage,

      handleIntersect,

      handleClickOpenLink,

      handleToggleBookMark,
    };
  },
});
